@use 'function/var';
@use 'function/root';

.main {
  position: relative;

  &__logo {
    display: none;

    @include var.minmq(var.$lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      flex: 0 0 auto;
      width: 196px;
      height: 196px;
      /*background-color: var(--color-key);
      border-radius: 0 0 var(--border-radius-large) 0;*/

      img {
        width: 138px;
        height: auto;
      }
    }

    &--link {
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
      transition: opacity .4s var(--easing-cubic1);

      @include var.hover {
        opacity: .5;
      }
    }
  }
}