@use 'function/var';
@use 'function/root';

/*
 * [note] カード型リストパーツ
 * ------------------------------------------------ */

.cardlist {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px 20px;

  @include var.minmq(var.$lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 20px;
  }

  &__item {
    .is-type1 & {
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);
    }

    &--img {
      margin-bottom: 0;

      img {
        width: 100%;
        height: auto;
      }

      .is-type1 & {
        img {
          border-start-start-radius: var(--border-radius-small);
          border-start-end-radius: var(--border-radius-small);
        }
      }

      .is-type2 & {
        img {
          border-radius: var(--border-radius-small);
        }
      }
    }

    &--contents {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .is-type1 & {
        padding: 24px 24px 64px;
      }

      .is-type2 & {
        gap: 14px;
        padding-block-start: 16px;
      }
    }
  }

  &__hgroup {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.is-center {
      align-items: center;
      text-align: center;
    }
  }

  &__sublabel {
    display: inline-flex;
    gap: 6px;
    align-items: baseline;
    font-family: var(--font-en);
    font-weight: 500;
    line-height: calc(18 / 14);
    color: var(--text-color1);
    @include var.fontsize(14);

    .is-large {
      @include var.fontsize(24);
      font-weight: 600;
    }
  }

  &__mainlabel {
    line-height: calc(32 / 20);
    font-weight: 600;
    @include var.fontsize(20);

    .is-type2 & {
      color: var(--text-color1);
      line-height: calc(24 / 18);
      @include var.fontsize(18);
    }

    @include var.minmq(var.$lg) {
      .is-type2 & {
        line-height: calc(22 / 16);
        @include var.fontsize(16);
      }
    }
  }

  &__text {
    line-height: calc(28 / 15);
    font-weight: 400;
    @include var.fontsize(15);
    @include var.kerning(-10);

    .is-type1 & {
      line-height: calc(36 / 18);
      @include var.fontsize(18);
    }

    @include var.minmq(var.$lg) {
      .is-type1 & {
        line-height: calc(32 / 16);
        @include var.fontsize(16);
      }
    }

  }
}