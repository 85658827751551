@use 'function/var';
@use 'function/root';

.footer {
  padding-block: 80px 24px;
  padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);
  position: relative;
  z-index: 1;

  @include var.minmq(var.$lg) {
    padding-inline: 26px;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    max-width: 1000px;
    margin-inline: auto;
  }

  &__nav {
    display: grid;
    grid-template-areas:
      'nav1st'
      'nav2nd'
      'nav3rd'
      'nav4th';
    grid-template-columns: 1fr;
    gap: 40px 20px;
    padding-block-end: 16px;

    @include var.minmq(var.$xs) {
      grid-template-areas:
        'nav1st nav1st'
        'nav2nd nav3rd'
        'nav4th nav4th';
      grid-template-columns: auto auto;
    }

    @include var.minmq(var.$md) {
      grid-template-areas:
        'nav1st nav2nd'
        'nav3rd nav4th';
      gap: 40px;
      justify-content: center;
    }

    @include var.minmq(var.$lg) {
      grid-template-areas:
        'nav1st nav2nd nav3rd nav4th';
      grid-template-columns: 1fr auto auto auto;
      gap: 0 60px;
    }

    &--block {
      &.is-1st {
        grid-area: nav1st;
      }

      &.is-2nd {
        grid-area: nav2nd;
      }

      &.is-3rd {
        grid-area: nav3rd;
      }

      &.is-4th {
        grid-area: nav4th;
      }
    }


    &--list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 18px;
    }

    &--item {}

    &--label {
      font-weight: 600;

      @include var.fontsize(16);

      @include var.minmq(var.$lg) {
        @include var.fontsize(15);
      }
    }

    &--link {
      font-weight: 600;
      transition: color .4s var(--easing-cubic1);

      @include var.fontsize(16);

      @include var.hover {
        color: var(--btn-color1);
      }

      &.is-light {
        font-weight: 400;

        @include var.fontsize(15);
      }

      @include var.minmq(var.$lg) {
        @include var.fontsize(15);

        &.is-light {
          @include var.fontsize(14);
        }
      }

      &[target="_blank"] {
        display: inline-flex;
        gap: var.clamp-px(16px, 24px, 32px, var.$sm);
        align-items: center;

        &::after {
          flex: 0 0 auto;

          // position: absolute;
          // top: 50%;
          // right: 16px;
          width: 10px;
          height: 10px;
          content: '';
          background-color: var(--icon-color2);
          mask: url('../img/common/icon-blank.svg') no-repeat;
          translate: 0 1px;
          transition: background-color .4s var(--easing-cubic1);
        }

        @include var.hover {

          // color: var(--btn-color1);
          &::after {
            background-color: var(--btn-color1);
          }
        }
      }
    }
  }

  &__subnav {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-block-start: 16px;

    &--item {
      position: relative;
      padding-inline-start: 16px;

      &::before {
        position: absolute;
        top: 13px;
        left: 2px;
        width: 8px;
        height: 1px;
        content: '';
        background-color: #C6C6C6;
      }
    }

    &--link {
      position: relative;
      font-weight: 400;
      color: var(--color-link);

      @include var.fontsize(15);

      transition: color .4s var(--easing-cubic1);

      @include var.hover {
        color: var(--btn-color1);
      }

      @include var.minmq(var.$lg) {
        @include var.fontsize(14);
      }

      &[target="_blank"] {
        display: inline-flex;
        gap: var.clamp-px(16px, 24px, 32px, var.$sm);
        align-items: center;

        &::after {
          flex: 0 0 auto;

          // position: absolute;
          // top: 50%;
          // right: 16px;
          width: 10px;
          height: 10px;
          content: '';
          background-color: var(--icon-color2);
          mask: url('../img/common/icon-blank.svg') no-repeat;
          translate: 0 1px;
          transition: background-color .4s var(--easing-cubic1);
        }

        @include var.hover {

          // color: var(--btn-color1);
          &::after {
            background-color: var(--btn-color1);
          }
        }
      }
    }
  }

  &__aeonblock {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @include var.minmq(var.$lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--list {
      display: flex;
      gap: 24px;
      align-items: center;
      justify-content: center;
    }

    &--link {
      transition: opacity .4s var(--easing-cubic1);

      @include var.hover {
        opacity: .5;
      }
    }
  }

  &__copyright {
    @include var.fontsize(12);
    @include var.kerning(10);

    font-family: var(--font-en);
    font-weight: 400;
    color: var(--text-color3);
    text-align: center;
  }

}