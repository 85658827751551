@use 'function/var';
@use 'function/root';

.pagetitle {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background: rgba($color: #383838, $alpha: .7);
    mix-blend-mode: multiply;
  }

  &__hgroup {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    translate: -50% -50%;

    @include var.minmq(var.$lg) {
      translate: -50% calc(-50% + 49px);
    }

  }

  &__label {
    font-size: var.clamp-px(28px, 30px, 32px, var.$md);
    font-weight: 600;
    color: var(--text-color2);
  }

  &__sublabel {
    line-height: calc(18 / 14);
    @include var.fontsize(14);
    font-family: var(--font-en);
    font-weight: 500;
    color: var(--text-color2);
  }

  &__bg {
    height: 240px;

    @include var.minmq(var.$lg) {
      height: 384px;
    }

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}