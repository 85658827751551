@use 'function/var';
@use 'function/root';

/*
 * [note] テーブルパーツ
 * ------------------------------------------------ */

.tbl {
  width: 100%;
  // table-layout: fixed;
  border-collapse: separate;
  border-spacing: 1px;

  &__thead {
    padding-block: 32px 24px;
    padding-inline: 24px;
    color: var(--text-color2);
    text-align: center;
    line-height: calc(20 / 15);
    font-weight: 600;
    @include var.fontsize(15);
    background-color: #FF9E4B;

    &.is-empty {
      background: none;

      &+.tbl__thead {
        border-start-start-radius: var(--border-radius-small);
      }
    }

    thead & {
      &:last-child {
        border-start-end-radius: var(--border-radius-small);
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        th {
          border-start-start-radius: var(--border-radius-small);
        }
      }

      &:last-child {
        th {
          border-end-start-radius: var(--border-radius-small);
        }

        td {
          &:last-child {
            border-end-end-radius: var(--border-radius-small);
          }
        }
      }
    }

    th,
    td {
      line-height: calc(20 / 14);
      font-weight: 500;
      @include var.fontsize(13);
      text-align: center;
      padding-block: 24px;
      padding-inline: 24px;
      // border-top: 1px solid var(--line-color6);
      background-color: var(--bg-color5);
    }

    .tbl__tbody {
      &--tcel {
        width: 312px;
        padding-inline-start: 48px;
      }

      &--genre {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;
        margin-block-end: 0;
      }

      &--mark {
        display: grid;
        place-items: center;
        flex: 0 0 auto;
        background-color: var(--bg-color5);
        border-radius: var(--border-radius-small);
        border: 2px solid var(--line-color1);
      }

      &--storecat {
        font-weight: 600;
        color: var(--text-color1);
        @include var.fontsize(16);
      }
    }
  }

}