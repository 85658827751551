@use 'function/var';
@use 'function/root';

.home {
  position: relative;

  &__logo {
    position: absolute;
    top: 0;
    left: 8px;
    z-index: 5;
    flex: 0 0 auto;
    width: 120px;
    height: 120px;
    /*background-color: var(--color-key);*/
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      top: 0;
      left: 0;
      width: 196px;
      height: 196px;
      border-radius: 0 0 var(--border-radius-large) 0;

      img {
        width: 138px;
      }
    }

    &--link {
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
      transition: opacity .4s var(--easing-cubic1);

      @include var.hover {
        opacity: .5;
      }
    }
  }

  // MARK:キービジュアル
  &__kv {
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: '';
      background: url('../img/home/txt-3fit.svg') center calc(100% + 35px) no-repeat;
      background-size: 102% auto;
      opacity: .15;

      @include var.minmq(var.$md) {
        background: url('../img/home/txt-3fitfitness.svg') center calc(100% + 42px) no-repeat;
        background-size: 101% auto;
      }
    }

    &--spkv {
      position: relative;
      height: 632px;

      img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
        background: linear-gradient(rgb(255 255 255 / 0%) 75%, rgb(134 134 134 / 100%) 100%);
        mix-blend-mode: multiply;
      }
    }

    .splide__slide {
      position: relative;

      @include var.minmq(var.$md) {
        height: 768px;
      }

      img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
        background: linear-gradient(rgb(255 255 255 / 0%) 75%, rgb(134 134 134 / 100%) 100%);
        mix-blend-mode: multiply;
      }
    }

    &--slogan {
      position: absolute;
      bottom: 32px;
      left: 50%;
      display: flex;
      flex-flow: column wrap;
      gap: 16px;
      align-content: center;
      width: 100%;
      padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);
      translate: -50% 0;
      z-index: 1;

      @include var.minmq(var.$md) {
        bottom: 48px;
        left: var.clamp-px(26px, 56px, 72px, var.$xlg);
        gap: 20px;
        align-content: flex-start;

        // max-width: calc(1000px + var.clamp-px(20px, 26px, 26px, var.$sm));
        translate: 0 0;
      }

      @include var.minmq(var.$lg) {
        bottom: 64px;
        padding-inline: 26px;

        // padding-inline: 26px;
      }

      @include var.minmq(var.$xlg) {
        // translate: calc(-50% - 110px) 0;
      }
    }

    &--slogantext {
      font-weight: 500;
      line-height: calc(24 / 18);
      color: var(--text-color2);

      @include var.fontsize(18);
    }

    &--sloganword {
      @include var.minmq(var.$lg) {
        img {
          width: 390px;
        }
      }
    }
  }


  // MARK: home title
  &__title {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .is-sublabel {
      font-family: var(--font-en);
      font-weight: 500;
      line-height: calc(18 / 14);
      color: var(--text-color1);
      @include var.fontsize(14);
    }

    .is-mainlabel {
      font-weight: 500;
      line-height: calc(33 / 24);
      // color: var(--text-color1);
      @include var.fontsize(24);
    }
  }


  // MARK:NEWS
  &__news {
    padding: 64px var.clamp-px(20px, 26px, 26px, var.$sm);
    background-color: var(--bg-color3);

    @include var.minmq(var.$lg) {
      padding-block: 96px;
      padding-inline: 26px;
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;

      @include var.minmq(var.$lg) {
        display: grid;
        grid-template-columns: 1fr 760px;
        gap: 24px;
        max-width: 1000px;
      }
    }

    &--headline {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto 1fr;
      grid-template-areas:
        'title morelink'
        'tabs tabs';
      gap: 21px 0;

      @include var.minmq(var.$lg) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
          'title'
          'tabs'
          'morelink';
        gap: 26px 0;
      }
    }

    .home__title {
      grid-area: title;
    }

    &--tabs {
      grid-area: tabs;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid var(--line-color2);

      @include var.minmq(var.$lg) {
        display: grid;
        grid-template-columns: auto;
        gap: 6px;
        border-bottom: none;
        translate: 12px 0;
      }
    }

    &--tablink {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-inline: 8px;
      min-height: 56px;
      border-start-start-radius: var(--border-radius-small);
      border-start-end-radius: var(--border-radius-small);
      font-weight: 600;
      line-height: calc(22 / 16);
      @include var.fontsize(16);
      transition: color .4s var(--easing-cubic1), background-color .4s var(--easing-cubic1), opacity .4s var(--easing-cubic1);

      &[aria-selected="true"] {
        [data-tabgroup] & {
          color: var(--text-color2);
          background-color: var(--bg-color1);
        }
      }

      &[aria-selected="false"] {
        [data-tabgroup] & {
          /*color: var(--text-color3);
          background-color: transparent;*/
          background-color: rgba(0, 0, 0, 0);
          color: var(--text-color3);
          border: 1px solid var(--bg-color1);
          @include var.minmq(var.$lg) {
            border: none;
          }
        }
      }

      @include var.minmq(var.$lg) {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        min-height: auto;
        padding: 0;
        border-radius: 0;
        line-height: calc(19 / 14);
        @include var.fontsize(14);
        position: relative;
        padding-inline-start: 12px;

        &[aria-selected="true"] {
          [data-tabgroup] & {
            color: var(--btn-color2);
            background: none;

            &::before {
              content: '・';
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }

      }

      @include var.hover {
        opacity: .5;
      }
    }

    &--more {
      grid-area: morelink;
      align-self: flex-end;
      translate: 0 -4px;

      .is-label {
        @include var.fontsize(16);
        font-weight: 500;
      }

      @include var.minmq(var.$lg) {
        translate: 12px 0;
        align-self: flex-start;

        .is-label {
          @include var.fontsize(14);
        }
      }
    }


    &--archive {
      width: 100%;

      @include var.minmq(var.$lg) {
        border-top: 1px solid var(--line-color2);
      }
    }

    &--block {
      transition: opacity .4s var(--easing-cubic1);

      &[aria-hidden="true"] {
        display: none;
      }

      &[aria-hidden="false"] {
        animation: inviewfadein .4s var(--easing-cubic1) forwards;
      }
    }
  }

  // MARK:ピックアップ
  &__pickup {
    padding-block: 64px;
    background-color: var(--bg-color2);
    position: relative;

    @include var.minmq(var.$lg) {
      padding-block: 96px;

      &::after {
        content: '';
        width: 56%;
        height: 100%;
        background-color: var(--bg-color6);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
      }
    }

    &--slider {
      overflow-x: clip;

      img {
        border-radius: var(--border-radius-small);
      }

      .splide__track {
        max-width: 1000px;
        margin-inline: auto;
        overflow: visible;
        z-index: 2;
      }

      .splide__pagination {
        max-width: calc(578px + var.clamp-px(20px, 26px, 26px, var.$sm));
//         width: 100%;
        justify-content: flex-end;
        padding: 0 var.clamp-px(20px, 26px, 26px, var.$sm) 0 0;
//         top: 28px;
		top: 100%;
//         bottom: auto;
//         left: 50%;
		left: 45%;
        right: auto;
        z-index: 2;
//         translate: -50% 0;

        @include var.minmq(var.$lg) {
          max-width: 1052px;
          padding-inline: 26px;
        }
      }

      .splide__pagination__page {
        margin: 0 6px;
        opacity: 1;
        background: var(--color-gray5);

        &.is-active {
          background: #E26902;
          transform: scale(1);
        }
      }
    }

    &--link {
      transition: opacity .4s var(--easing-cubic1);

      @include var.hover {
        opacity: .5;
      }
    }

    .home__title {
      max-width: calc(578px + 42px);
      margin-inline: auto;
      padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);
      margin-block-end: 21px;

      .is-mainlabel {
        color: var(--text-color2);
      }

      @include var.minmq(var.$lg) {
        max-width: 1052px;
        padding-inline: 26px;
        margin-block-end: 36px;
      }
    }
  }

  // MARK:店舗検索
  &__search {
    padding: 80px var.clamp-px(20px, 26px, 26px, var.$sm) 64px;
    position: relative;
    background-color: var(--bg-color4);
    overflow: hidden;

    @include var.minmq(var.$lg) {
      padding-block: 120px;
      padding-inline: 26px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: '';
      background: url('../img/home/txt-search.svg') right top no-repeat;
      background-size: 100% auto;

      @include var.minmq(var.$sm) {
        background-position: right -6px;
        background-size: 430px auto;
      }

      @include var.minmq(var.$lg) {
        background-size: 864px auto;
      }
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      position: relative;
      z-index: 2;

      .is-modal & {
        position: static;
      }

      @include var.minmq(var.$lg) {
        max-width: 1000px;

        .is-modal & {
          position: relative;
        }
      }
    }

    .home__title {
      margin-block-end: calc(24px + 20px);

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--line-color3);
        position: absolute;
        left: 0;
        bottom: 0;
        translate: 0 20px;
      }

      @include var.minmq(var.$lg) {
        margin-block-end: calc(32px + 28px);

        .is-mainlabel {
          @include var.fontsize(32);
        }

        &::after {
          translate: 0 28px;
        }
      }
    }
  }

  // MARK:ブランド
  &__brand {
    position: relative;
    background-color: #ff8227;
    overflow: hidden;

    @include var.minmq(var.$lg) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        content: '';
        background: url('../img/home/bg-brand.svg') left top no-repeat;
        background-size: 100% auto;
        z-index: 0;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      content: '';
      background: url('../img/home/txt-brands.svg') -4px top no-repeat;
      background-size: 102% auto;
      z-index: 1;
      opacity: .7;

      @include var.minmq(var.$lg) {
        background-position: -4px -2px;
      }
    }

    &--wrapper {
      padding: 80px var.clamp-px(20px, 26px, 26px, var.$sm) 64px;

      @include var.minmq(var.$lg) {
        padding-block: 120px calc(120px + 275px);
        padding-inline: 26px;

        // &::before {
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 275px;
        //   pointer-events: none;
        //   content: '';
        //   background-color: var(--bg-color1);
        //   z-index: 0;
        // }
      }
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      position: relative;
      z-index: 2;

      @include var.minmq(var.$lg) {
        max-width: 1000px;
      }
    }

    .home__title {
      color: var(--text-color2);
      margin-block-end: 24px;

      .is-sublabel {
        color: var(--text-color2);
      }

      @include var.minmq(var.$lg) {
        margin-block-end: 120px;
      }
    }

    &--block {
      max-width: 578px;
      margin-inline: auto;
      border-radius: var(--border-radius-medium);
      background-color: var(--bg-color3);

      @include var.minmq(var.$lg) {
        display: flex;
        align-items: center;
        max-width: 840px;
        min-height: 484px;
        margin-inline-end: 0;

        &.is-reverse {
          flex-direction: row-reverse;
          margin-inline-start: 0;
          margin-inline-end: auto;
        }
      }

      &+& {
        margin-block-start: 48px;

        @include var.minmq(var.$lg) {
          margin-block-start: 120px;
        }
      }
    }

    &--img {
      margin-block-end: 0;

      img {
        width: 100%;
        height: auto;
        border-start-start-radius: var(--border-radius-medium);
        border-start-end-radius: var(--border-radius-medium);
      }

      @include var.minmq(var.$lg) {
        flex: 0 0 auto;
        margin-inline-start: -160px;

        img {
          width: 440px;
          border-radius: var(--border-radius-medium);
        }

        .is-reverse & {
          margin-inline-start: 0;
          margin-inline-end: -160px;
        }
      }

    }

    &--content {
      padding: 32px var.clamp-px(16px, 24px, 24px, var.$sm) 48px;

      @include var.minmq(var.$lg) {
        flex: 0 1 auto;
        padding: 60px 72px 64px 50px;

        .is-reverse & {
          padding-inline: 72px 50px;
        }
      }
    }

    &--hgroup {
      display: flex;
      flex-direction: column;
      gap: 0;
      padding-inline-start: 60px;
      position: relative;

      @include var.minmq(var.$lg) {
        padding-inline-start: 76px;
      }

      .is-mainlabel {
        font-family: var(--font-en);
        font-weight: 600;
        color: var(--text-color1);
        @include var.fontsize(28);
      }

      .is-sublabel {
        font-weight: 600;
        color: var(--text-color1);
        @include var.fontsize(16);
      }

      @include var.minmq(var.$lg) {
        .is-mainlabel {
          @include var.fontsize(36);
        }

        .is-sublabel {
          @include var.fontsize(16);
        }
      }

      &::before {
        content: '';
        width: 48px;
        height: 48px;
        border: 2px solid var(--line-color1);
        border-radius: var(--border-radius-small);
        position: absolute;
        top: 6px;
        left: 0;

        @include var.minmq(var.$lg) {
          width: 64px;
          height: 64px;
        }
      }

      &::after {
        content: '';
        width: 48px;
        height: 48px;
        background-color: var(--icon-color1);
        position: absolute;
        top: 6px;
        left: 0;

        @include var.minmq(var.$lg) {
          width: 64px;
          height: 64px;
        }
      }

      &.is-sports {
        &::after {
          mask: url('../img/common/icon-sports.svg') center no-repeat;
          mask-size: contain;
        }
      }

      &.is-fitness {
        &::after {
          mask: url('../img/common/icon-fitness.svg') center no-repeat;
          mask-size: contain;
        }
      }

      &.is-wellness {
        &::after {
          mask: url('../img/common/icon-wellness.svg') center no-repeat;
          mask-size: contain;
        }
      }

      &.is-kids {
        &::after {
          mask: url('../img/common/icon-kids.svg') center no-repeat;
          mask-size: contain;
        }
      }

      &.is-online {
        &::after {
          mask: url('../img/common/icon-online.svg') center no-repeat;
          mask-size: contain;
        }
      }
    }

    &--inner {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      margin-block-start: 28px;

      @include var.minmq(var.$lg) {
        margin-block-start: 26px;
      }
    }

    &--subtitle {
      font-weight: 600;
      line-height: calc(32 / 20);

      @include var.fontsize(20);

      @include var.minmq(var.$lg) {
        line-height: calc(27 / 20);
      }
    }

    &--text {
      font-weight: 500;
      line-height: calc(36 / 18);

      @include var.fontsize(18);
      @include var.kerning(-10);

      @include var.minmq(var.$lg) {
        line-height: calc(32 / 16);

        @include var.fontsize(16);
      }
    }

    &--more {
      display: flex;
      justify-content: center;
      margin-block-start: 62px;

      @include var.minmq(var.$lg) {
        margin-block-start: 56px;
        justify-content: flex-start;
      }
    }
  }

  // MARK:3FITについて
  &__about {
    background-color: #292929;
    position: relative;

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
      background-color: transparent;
      margin-block-end: -275px;
      translate: 0 -275px;
    }

    &--img {
      margin-block-end: 0;
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #383838, $alpha: .8);
        mix-blend-mode: multiply;
        pointer-events: none;
        content: '';
        z-index: 1;
      }

      @include var.minmq(var.$lg) {
        max-width: 1270px;
        margin-inline: auto;

        img {
          height: 549px;
          object-fit: cover;
          object-position: center right;
          border-radius: var(--border-radius-medium);
        }

        &::before {
          border-radius: var(--border-radius-medium);
          background: linear-gradient(90deg, #1d1d1d 50%, #7a7a7a 100%);
        }
      }
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding: 64px var.clamp-px(20px, 26px, 26px, var.$sm) 80px;
      position: relative;
      z-index: 2;

      @include var.minmq(var.$lg) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 1104px;
        width: 100%;
        height: 100%;
        padding-inline: 52px;
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50% 0;
      }
    }

    .home__title {
      color: var(--text-color2);
      margin-block-end: 34px;

      @include var.minmq(var.$lg) {
        margin-block-end: 48px;
      }
    }

    &--text {
      color: var(--text-color2);
      line-height: calc(36 / 18);
      @include var.fontsize(18);

      @include var.minmq(var.$lg) {
        max-width: 600px;
        line-height: calc(36 / 16);
        @include var.fontsize(16);
      }
    }

    &--linklist {
      display: grid;
      grid-template-columns: 1fr;
      gap: 24px;
      margin-block-start: 70px;

      @include var.minmq(var.$lg) {
        display: inline-grid;
        grid-template-columns: 192px 192px;
        gap: 16px;
      }
    }

    &--linkitem {
      display: flex;
      justify-content: center;

      @include var.minmq(var.$lg) {
        justify-content: flex-start;
      }
    }
  }
}