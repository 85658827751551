@use 'var';

:root {
  // breakpoint
  --breakpoint-md: #{var.$md};
  --breakpoint-lg: #{var.$lg};

  // font
  --font-default: 'Noto Sans JP', sans-serif;
  --font-en: 'Montserrat', sans-serif;

  // Header Height
  --header-height: 64px;

  // color
  --color-black: #000;
  --color-white: #fff;
  --color-gray1: #383838;
  --color-gray2: #F4F4F4;
  --color-gray3: #E0E0E0;
  --color-gray4: #EAEAEA;
  --color-gray5: #9B9B9B;
  --color-gray6: #414141;
  --color-red1: #E01111;
  --color-orange1: #FA7300;
  --color-orange2: #FC7300;
  --color-purple: #B80081;

  // color set
  --color-txtbase: var(--color-gray1);
  --color-link: var(--color-gray1);

  // key color set
  --color-key: var(--color-orange1);
  --color-aeon: var(--color-purple);

  // color set
  --text-color1: var(--color-orange2);
  --text-color2: var(--color-white);
  --text-color3: var(--color-gray5);
  --btn-color1: var(--color-orange1);
  --btn-color2: var(--color-gray1);
  --btn-color3: var(--color-white);
  --btn-cta: var(--color-red1);
  --line-color1: var(--color-orange2);
  --line-color2: var(--color-gray3);
  --line-color3: var(--color-gray5);
  --line-color4: var(--color-white);
  --line-color5: var(--color-gray4);
  --line-color6: var(--color-gray2);
  --icon-color1: var(--color-orange2);
  --icon-color2: var(--color-gray1);
  --icon-color3: var(--color-white);
  --icon-color4: var(--color-gray3);
  --ttlbar-color1: var(--color-orange2);
  --ttlbar-color2: var(--color-red1);
  --bg-color1: var(--color-orange2);
  --bg-color2: var(--color-gray1);
  --bg-color3: var(--color-gray2);
  --bg-color4: var(--color-gray4);
  --bg-color5: var(--color-white);
  --bg-color6: var(--color-gray6);

  // border
  --border-radius: 4px;
  --border-radius-small: 8px;
  --border-radius-medium: 12px;
  --border-radius-large: 24px;
  --border-halfcircle: 40px;
  --border-circle: 50%;

  // easing
  --easing-cubic1: cubic-bezier(0, 0, 0, 1);

}

@include var.minmq(var.$lg) {
  :root {
    --header-height: 120px;
  }
}