@use 'function/var';
@use 'function/root';

.gnav {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - var(--header-height));
  background-color: var(--bg-color3);
  opacity: 0;
  transition: opacity .6s var(--easing-cubic1);

  &[aria-hidden="true"] {
    pointer-events: none;
  }

  &[aria-hidden="false"] {
    pointer-events: all;
  }

  .is-spnav & {
    opacity: 1;
  }

  @include var.minmq(var.$lg) {
    // position: fixed;
    top: 24px;
    right: 26px;

    // z-index: 15;
    display: block;

    // flex-direction: column;
    max-width: var.clamp-px(764px, 984px, 984px, var.$xlg);
    height: 72px;
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);
    opacity: 1;
  }

  &__logo {
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    /*margin-block-end: calc(-36px + 16px);*/
    /*background-color: var(--color-key);
    border-radius: var(--border-radius-small);*/
    translate: var.clamp-px(20px, 26px, 26px, var.$sm);
    margin-bottom: 20px;
    margin-top: 10px;

    &--link {
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
    }

    @include var.minmq(var.$lg) {
      display: none;
    }
  }

  &__container {
    display: grid;
    flex: 0 1 auto;
    grid-template-columns: 1fr;
    gap: var.clamp-px(30px, 40px, 40px, var.$sm);
    padding-block-end: 48px;
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);
    overflow-y: auto;

    @include var.minmq(var.$lg) {
      display: flex;
      gap: var.clamp-px(8px, 12px, 16px, var.$xlg);
      justify-content: space-between;
      height: 100%;
      padding: 8px 8px 8px var.clamp-px(8px, 20px, 32px, 1200px);
      overflow-y: visible;
    }

  }

  &__menu {
    border-top: 1px solid var(--line-color3);

    @include var.minmq(var.$lg) {
      display: flex;
      flex: 0 1 auto;
      gap: var.clamp-px(8px, 20px, 32px, 1200px);
      align-items: center;
      border-top: none;
    }

    @include var.minmq(1200px) {
      gap: 32px;
    }

    &--item {
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        border-bottom: none;

        &.is-megamenu {
          .gnav__menu--link {
            color: var(--btn-color1);
          }

          .gnav__menu--inner {
            animation: inviewfadein .4s var(--easing-cubic1) forwards;
          }

          &::after {
            content: '';
            position: absolute;
            top: 64px;
            left: 0;
            width: 736px;
            height: 40px;
          }
        }
      }
    }

    &--link {
      position: relative;
      display: block;
      padding-block: 20px;
      font-weight: 500;
      line-height: calc(30 / 22);
      color: var(--color-link);

      @include var.fontsize(22);

      &::after {
        position: absolute;
        top: 50%;
        right: 16px;
        width: 10px;
        height: 10px;
        content: '';
        background-color: var(--icon-color1);
        mask: url('../img/common/icon-arrow2.svg') no-repeat;
        translate: 0 -50%;
      }

      &[data-gnavtoggle] {
        .is-icon {
          position: absolute;
          top: 17px;
          right: 6px;
          width: 32px;
          height: 32px;
          border: 1px solid var(--line-color1);
          border-radius: var(--border-circle);
          pointer-events: none;

          &::before,
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 2px;
            content: '';
            background-color: var(--icon-color1);
            translate: -50% -50%;
          }

          &::after {
            rotate: 90deg;
            transition: rotate .4s var(--easing-cubic1);
          }
        }

        &::after {
          display: none;
        }
      }

      &[aria-expanded="true"] {
        .is-icon {
          &::after {
            rotate: 0deg;
          }
        }
      }

      @include var.minmq(var.$lg) {
        // position: relative;
        // display: block;
        padding-block: 0;
        font-size: var.clamp-px(14px, 14px, 15px, 1200px);
        font-weight: 600;
        white-space: nowrap;
        line-height: calc(20 / 15);
        transition: color .4s var(--easing-cubic1);

        &::after {
          display: none;
        }

        .is-icon {
          display: none;
        }

        @include var.hover {
          color: var(--btn-color1);
        }
      }
    }

    &--inner {
      padding-block: 0 32px;

      &[aria-hidden="true"] {
        display: none;
        pointer-events: none;
      }

      &[aria-hidden="false"] {
        display: block;
        opacity: 1;
        pointer-events: all;
      }

      @include var.minmq(var.$lg) {
        position: absolute;
        top: 88px;
        left: 0;
        width: auto;
        padding: 24px 24px 14px;
        opacity: 0;
        transition: opacity .4s var(--easing-cubic1);

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          background-color: #595959;
          border-radius: var(--border-radius-small);
          mix-blend-mode: multiply;
        }
      }

    }
  }

  &__submenu {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @include var.minmq(var.$lg) {
      display: flex;
      // grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    &--item {
      position: relative;
      padding-inline-start: 24px;

      &::before {
        position: absolute;
        top: 14px;
        left: 2px;
        width: 12px;
        height: 1px;
        content: '';
        background-color: var(--line-color3);
      }

      @include var.minmq(var.$lg) {
        padding: 0;

        &::before {
          display: none;
        }
      }
    }

    &--link {
      position: relative;
      display: flex;
      gap: var.clamp-px(16px, 32px, 32px, var.$sm);
      align-items: center;
      font-weight: 500;
      color: var(--color-link);

      @include var.fontsize(18);

      &::after {
        flex: 0 0 auto;

        // position: absolute;
        // top: 50%;
        // right: 16px;
        width: 10px;
        height: 10px;
        content: '';
        background-color: var(--icon-color1);
        mask: url('../img/common/icon-arrow2.svg') no-repeat;
        translate: 0 1px;
      }

      @include var.minmq(var.$lg) {
        display: flex;
        flex-direction: column;
        gap: 13px;
        font-weight: 500;
        color: var(--text-color2);
        text-align: center;

        @include var.fontsize(13);

        .is-label {
          line-height: calc(15 / 13);
        }

        .is-small {
          @include var.fontsize(12);
        }

        &::after {
          display: none;
        }

        &::before {
          width: 160px;
          height: 96px;
          content: '';
          border-radius: var(--border-radius);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: opacity .4s var(--easing-cubic1);
        }

        &.is-sports {
          &::before {
            background-image: url('../img/common/img-gnav1.jpg');
            background-image: image-set('../img/common/img-gnav1.webp' type('image/webp'), '../img/common/img-gnav1.jpg' type('image/jpeg'));
          }
        }

        &.is-fitness {
          &::before {
            background-image: url('../img/common/img-gnav2.jpg');
            background-image: image-set('../img/common/img-gnav2.webp' type('image/webp'), '../img/common/img-gnav2.jpg' type('image/jpeg'));
          }
        }

        &.is-wellness {
          &::before {
            background-image: url('../img/common/img-gnav3.jpg');
            background-image: image-set('../img/common/img-gnav3.webp' type('image/webp'), '../img/common/img-gnav3.jpg' type('image/jpeg'));
          }
        }

        &.is-kids {
          &::before {
            background-image: url('../img/common/img-gnav4.jpg');
            background-image: image-set('../img/common/img-gnav4.webp' type('image/webp'), '../img/common/img-gnav4.jpg' type('image/jpeg'));
          }
        }

        &.is-online {
          &::before {
            background-image: url('../img/common/img-gnav5.jpg');
            background-image: image-set('../img/common/img-gnav5.webp' type('image/webp'), '../img/common/img-gnav5.jpg' type('image/jpeg'));
          }
        }

        @include var.hover {
          &::before {
            opacity: .5;
          }
        }
      }
    }
  }

  &__cta {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    @include var.minmq(var.$lg) {
      flex: 0 1 292px;
      gap: 0;
      justify-content: center;
    }

    &--item {
      flex: 0 1 100%;
    }

    &--link {
      display: flex;
      gap: 6px;
      place-items: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 64px;
      color: var(--text-color2);
      border-radius: var(--border-radius);

      .is-label {
        font-size: var.clamp-px(15px, 18px, 18px, var.$sm);
        font-weight: 600;
        line-height: calc(24 / 18);
      }

      @include var.minmq(var.$lg) {
        min-height: 56px;
        transition: background-color .4s var(--easing-cubic1), color .4s var(--easing-cubic1);

        .is-label {
          font-size: var.clamp-px(14px, 14px, 15px, 1200px);
          // @include var.fontsize(15);
          line-height: calc(20 / 15);
        }
      }

      @include var.minmq(1200px) {
        @include var.fontsize(15);
      }

      &::before {
        width: 22px;
        height: 22px;
        content: '';
        background-color: currentColor;
        transition: background-color .4s var(--easing-cubic1);
      }

      &.is-admission {
        background-color: var(--btn-cta);
        border: 1px solid var(--btn-cta);

        &::before {
          mask: url('../img/common/icon-admission.svg') center no-repeat;
          mask-size: contain;
        }

        @include var.minmq(var.$lg) {
          border-start-end-radius: 0;
          border-end-end-radius: 0;

          @include var.hover {
            color: var(--btn-cta);
            background-color: var(--color-white);
          }
        }
      }

      &.is-member {
        background-color: var(--btn-color2);
        border: 1px solid var(--btn-color2);

        &::before {
          mask: url('../img/common/icon-user.svg') center no-repeat;
          mask-size: contain;
        }

        @include var.minmq(var.$lg) {
          border-start-start-radius: 0;
          border-end-start-radius: 0;

          @include var.hover {
            color: var(--btn-color2);
            background-color: var(--color-white);
          }
        }
      }
    }
  }
}