@use 'function/var';
@use 'function/root';

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--bg-color5);

  .is-spnav .trigger-txt { opacity: 0; }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: var(--header-height);
    pointer-events: none;
    content: '';
    background-color: var(--bg-color5);
    opacity: 0;
    transition: opacity .6s var(--easing-cubic1);

    .is-spnav & {
      opacity: 1;
    }
  }

  &__nav {
    display: flex;
    flex: 0 1 100%;
    padding: 8px;

    &--item {
      flex: 0 1 100%;
    }

    &--link {
      display: flex;
      gap: var.clamp-px(4px, 4px, 6px, var.$xs);
      place-items: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .is-label {
        font-size: var.clamp-px(14px, 15px, 15px, var.$sm);
        font-weight: 500;
        line-height: calc(20 / 15);
        color: var(--text-color2);
      }

      &::before {
        width: 22px;
        height: 22px;
        content: '';
        background-color: var(--icon-color3);
      }

      &.is-admission {
        background-color: var(--btn-cta);
        border-start-start-radius: var(--border-radius);
        border-end-start-radius: var(--border-radius);

        &::before {
          mask: url('../img/common/icon-admission.svg') center no-repeat;
          mask-size: contain;
        }
      }

      &.is-member {
        background-color: var(--btn-color2);
        border-start-end-radius: var(--border-radius);
        border-end-end-radius: var(--border-radius);

        &::before {
          mask: url('../img/common/icon-user.svg') center no-repeat;
          mask-size: contain;
        }
      }
    }
  }

  &__trigger {
    position: relative;
    display: block;
    flex: 0 0 auto;
    width: 64px;
    height: var(--header-height);
    background-color: var(--bg-color5);

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      content: '';
      background-color: var(--btn-color2);
      border-radius: var(--border-halfcircle);
      transition: width .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1), translate .3s var(--easing-cubic1);
    }

    &::before {
      width: 20px;
      translate: -50% calc(-50% - 12px);
    }

    &::after {
      width: 16px;
      translate: calc(-50% - 2px) calc(-50% - 4px);
    }

    & .trigger-txt {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      translate: 0 calc(-50% - 12px);
      font-size: 11px;
      line-height: 1;
    }

    .is-spnav & {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;

      &::before {
        width: 24px;
        rotate: -45deg;
        translate: -50% -50%;
      }

      &::after {
        width: 24px;
        rotate: 45deg;
        translate: -50% -50%;
      }
      & .trigger-txt {
        display: none;
      }
      
    }
  }


  @include var.minmq(var.$xs) {}

  @include var.minmq(var.$lg) {
    display: none;
  }

}