@use 'function/var';
@use 'function/root';

// MARK:display
.d-none {
  display: none;
}

.d-block {
  display: block;
}

@include var.minmq(var.$xs) {
  .d-xs-none {
    display: none;
  }

  .d-xs-block {
    display: block;
  }
}

@include var.minmq(var.$sm) {
  .d-sm-none {
    display: none;
  }

  .d-sm-block {
    display: block;
  }
}

@include var.minmq(var.$md) {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }
}

@include var.minmq(var.$lg) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }
}

@include var.minmq(var.$xlg) {
  .d-xlg-none {
    display: none;
  }

  .d-xlg-block {
    display: block;
  }
}

// MARK:text-align
.is-align-c {
  text-align: center;
}

.is-align-r {
  text-align: right;
}

.is-align-l {
  text-align: left;
}

@include var.minmq(var.$xs) {
  .is-align-xs-c {
    text-align: center;
  }

  .is-align-xs-r {
    text-align: right;
  }

  .is-align-xs-l {
    text-align: left;
  }
}

@include var.minmq(var.$sm) {
  .is-align-sm-c {
    text-align: center;
  }

  .is-align-sm-r {
    text-align: right;
  }

  .is-align-sm-l {
    text-align: left;
  }
}

@include var.minmq(var.$md) {
  .is-align-md-c {
    text-align: center;
  }

  .is-align-md-r {
    text-align: right;
  }

  .is-align-md-l {
    text-align: left;
  }
}

@include var.minmq(var.$lg) {
  .is-align-lg-c {
    text-align: center;
  }

  .is-align-lg-r {
    text-align: right;
  }

  .is-align-lg-l {
    text-align: left;
  }
}

@include var.minmq(var.$xlg) {
  .is-align-xlg-c {
    text-align: center;
  }

  .is-align-xlg-r {
    text-align: right;
  }

  .is-align-xlg-l {
    text-align: left;
  }
}

// MARK:accessibility
.is-accessibility {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  font-size: 0;
}


// MARK:Key frame Animation
[data-inview="up"] {
  opacity: 0;

  &.is-show {
    animation: inviewup .4s var(--easing-cubic1) forwards;
  }
}

[data-inview="fade"] {
  opacity: 0;

  &.is-show {
    animation: inviewfadein .4s var(--easing-cubic1) forwards;
  }
}

[data-inview="swipe"] {
  position: relative;

  &::before {
    flex: 0 0 auto;
    display: block;
    content: '';
    width: 112px;
    height: 112px;
    opacity: 0;
    background: url('../img/common/icon-swipe.svg') center no-repeat rgba($color: #383838, $alpha: .7);
    // background-size: contain;
    border-radius: var(--border-radius-large);
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    pointer-events: none;

    @include var.minmq(var.$lg) {
      display: none;
    }

  }

  &.is-show {
    &::before {
      animation: animeswipe 1.5s ease-out forwards;
      animation-iteration-count: 3;
    }
  }
}


// ベーシック Keyフレームアニメーション
@keyframes inviewfadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes inviewfadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes inviewup {
  from {
    opacity: 0;
    translate: 0 20px;
  }

  to {
    opacity: 1;
    translate: 0 0;
  }
}

@keyframes inviewdown {
  from {
    opacity: 0;
    translate: 0 -20px;
  }

  to {
    opacity: 1;
    translate: 0 0;
  }
}


@keyframes animeswipe {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@include var.minmq(var.$lg) {
  @keyframes inviewup {
    from {
      opacity: 0;
      translate: 0 40px;
    }

    to {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes inviewdown {
    from {
      opacity: 0;
      translate: 0 -40px;
    }

    to {
      opacity: 1;
      translate: 0 0;
    }
  }
}