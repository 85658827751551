@use 'function/var';
@use 'function/root';

// ページ用のスタイル
.store {
  background-color: var(--bg-color3);

  &__wrapper {
    padding: 40px var.clamp-px(20px, 26px, 26px, var.$sm) 72px;
    position: relative;

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }
  }

  &__results {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);
    position: relative;

    &--noresult {
      line-height: calc(24 / 15);
      @include var.fontsize(15);
      text-align: center;
      padding-block-end: 64px;
    }

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }
  }

  &__container {
    max-width: 578px;
    margin-inline: auto;

    @include var.minmq(var.$lg) {
      max-width: 1000px;
    }
  }

  // 検索結果
  &__block {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'storename'
      'summarylist'
      'morelink';
    gap: 16px;
    padding: 24px;
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      grid-template-columns: 1fr auto;
      grid-template-areas:
        'storename morelink'
        'summarylist summarylist';
    }

    &+& {
      margin-block-start: 32px;
    }

    &--hgroup {
      grid-area: storename;
      display: grid;
      grid-template-columns: 48px 1fr;
      align-items: flex-start;
      gap: 8px;

      @include var.minmq(var.$lg) {
        gap: 16px;
      }
    }

    &--more {
      grid-area: morelink;
      display: flex;
      justify-content: flex-end;
    }

    &--mark {
      display: grid;
      place-items: center;
      flex: 0 0 auto;
      background-color: var(--bg-color5);
      border-radius: var(--border-radius-small);
      border: 2px solid var(--line-color1);
    }

    &--name {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;

      .is-storegenre {
        font-weight: 500;
        color: var(--text-color3);
        line-height: calc(20 / 15);
        @include var.fontsize(15);
      }

      .is-storename {
        font-weight: 600;
        line-height: calc(28 / 20);
        @include var.fontsize(20);
        transition: color .4s var(--easing-cubic1);

        @include var.hover {
          color: var(--text-color1);
        }
      }
    }

    &--summary {
      grid-area: summarylist;
    }

    &--item {
      display: grid;
      grid-template-columns: 74px 1fr;
      padding-block: 12px;
      border-top: 1px solid var(--line-color2);

      @include var.minmq(var.$lg) {
        grid-template-columns: 80px 1fr;
        border-top: none;
        padding-block: 6px;

        &:first-child {
          padding-block-start: 16px;
          border-top: 1px solid var(--line-color2);
        }
      }
    }

    &--itemlabel {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      line-height: calc(20 / 15);
      @include var.fontsize(15);

      @include var.minmq(var.$lg) {
        translate: 0 2px;
      }

      &::after {
        content: '：';
        flex: 0 0 auto;
      }
    }

    &--itemdetail {
      padding-left: 8px;
      line-height: calc(20 / 15);
      @include var.fontsize(15);

      &.is-address {
        @include var.minmq(var.$lg) {
          display: flex;
          flex-wrap: wrap;
          gap: 1em;
        }
      }
    }

    &--address {
      font-style: normal;
      line-height: calc(24 / 15);
      @include var.fontsize(15);
    }

    &--gmaplink {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      color: var(--text-color3);
      line-height: calc(24 / 15);
      text-decoration: underline;
      @include var.fontsize(15);
      transition: color .4s var(--easing-cubic1);

      &::after {
        flex: 0 0 auto;
        width: 10px;
        height: 10px;
        content: '';
        background-color: currentColor;
        mask: url('../img/common/icon-blank.svg') no-repeat;
        translate: 0 1px;
        transition: background-color .4s var(--easing-cubic1);
      }

      @include var.hover {
        text-decoration: none;
        color: var(--text-color1);
      }
    }

    &--tellink {
      color: var(--text-color1);
      line-height: calc(24 / 15);
      text-decoration: underline;
      @include var.fontsize(15);
      transition: color .4s var(--easing-cubic1);

      @include var.hover {
        text-decoration: none;
      }

      @include var.minmq(var.$lg) {
        pointer-events: none;
      }
    }

    &--text {
      line-height: calc(24 / 15);
      @include var.fontsize(15);
    }
  }

  #placeblock {
    &[aria-hidden="true"] {
      display: none;
      pointer-events: none;
    }

    &[aria-hidden="false"] {
      display: block;
      opacity: 1;
      pointer-events: all;
    }

    @include var.minmq(var.$lg) {
      &[aria-hidden="false"] {
        display: flex;
      }
    }
  }

  #searchparameters {
    &[aria-hidden="true"] {
      display: none;
      pointer-events: none;
    }

    &[aria-hidden="false"] {
      display: block;
      opacity: 1;
      pointer-events: all;
    }
  }

}

// 検索ボックスと店舗リストモーダル
.storesearch {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @include var.minmq(var.$lg) {
    grid-template-areas:
      'viewedstore currentloc'
      'storeplace storeplace';
    // ▼詳細検索追加するとき変更
    // grid-template-areas:
    //   'viewedstore currentloc'
    //   'storeplace storeplace'
    //   'storeselect storeselect';
    grid-template-columns: 1fr 240px;
    grid-template-rows: auto 1fr;
    gap: 32px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;

    .is-label {
      font-weight: 600;
      line-height: calc(26 / 19);
      @include var.fontsize(19);

      @include var.minmq(var.$lg) {
        line-height: calc(24 / 18);
        @include var.fontsize(18);
      }
    }

    &::before {
      display: grid;
      place-items: center;
      content: '';
      width: 40px;
      height: 40px;
      background-color: var(--bg-color1);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px auto;
      border-radius: var(--border-radius-small);
    }

    &.is-viewedstore {
      &::before {
        background-image: url('../img/common/icon-dumbbell.svg');
      }
    }

    &.is-currentloc {
      &::before {
        background-image: url('../img/common/icon-mappoint.svg');
      }
    }

    &.is-place {
      &::before {
        background-image: url('../img/common/icon-place.svg');
      }
    }

    &.is-place {
      &::before {
        background-image: url('../img/common/icon-place.svg');
      }
    }

    &.is-trigger {
      width: 100%;
      background: none;
      position: relative;
      margin: -12px var.clamp-px(-12px, -14px, -16px, var.$lg);
      padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);

      .is-label {
        pointer-events: none;
      }

      .is-icon {
        position: absolute;
        top: 17px;
        right: -8px;
        width: 32px;
        height: 32px;
        border: 1px solid var(--color-gray5);
        border-radius: var(--border-circle);
        pointer-events: none;

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 2px;
          content: '';
          background-color: var(--color-gray5);
          translate: -50% -50%;
        }

        &::after {
          rotate: 90deg;
          transition: rotate .4s var(--easing-cubic1);
        }
      }

      &::after {
        display: none;
      }

      &[aria-expanded="true"] {
        .is-icon {
          &::after {
            rotate: 0deg;
          }
        }
      }
    }
  }

  // MARK:最近見たお店
  &__viewedstore {
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      grid-area: viewedstore;
      display: flex;
      align-items: center;
      padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);
    }

    .storesearch__title {
      padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);

      @include var.minmq(var.$lg) {
        padding: 0 24px 0 0;
      }

    }

    &--store {
      padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);
      border-top: 1px solid var(--line-color5);

      @include var.minmq(var.$lg) {
        display: flex;
        align-items: center;
        height: 100%;
        border-top: none;
        border-left: 1px solid var(--line-color5);
        padding: 0 0 0 24px;
      }

    }

    &--link {
      @include var.fontsize(16);
      font-weight: 400;
      line-height: calc(22 / 16);
      color: var(--text-color1);

      @include var.hover {
        text-decoration: underline;
      }
    }

    &--nodata {
      @include var.fontsize(16);
      font-weight: 400;
      line-height: calc(22 / 16);
    }
  }

  // MARK:現在地
  &__currentloc {
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      grid-area: currentloc;
    }

    &--link {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);
      font-weight: 600;
      line-height: calc(26 / 19);
      position: relative;
      @include var.fontsize(19);
      transition: color .4s var(--easing-cubic1);

      @include var.hover {
        color: var(--text-color1);
      }

      @include var.minmq(var.$lg) {
        line-height: calc(24 / 18);
        @include var.fontsize(18);
      }

      &::before {
        display: grid;
        place-items: center;
        content: '';
        width: 40px;
        height: 40px;
        background-image: url('../img/common/icon-mappoint.svg');
        background-color: var(--bg-color1);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 22px auto;
        border-radius: var(--border-radius-small);
      }

      &::after {
        width: 16px;
        height: 16px;
        content: '';
        background-color: var(--icon-color1);
        mask: url('../img/common/icon-arrow2.svg') center no-repeat;
        mask-size: contain;
        transition: background-color .4s var(--easing-cubic1);
        position: absolute;
        top: 50%;
        right: 16px;
        translate: 0 -50%;

        @include var.minmq(var.$lg) {
          display: none;
        }

      }
    }
  }

  // MARK:場所から探す
  &__place {
    padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      grid-area: storeplace;
    }

    &--block {
      padding: 16px 10px 10px;
      margin-block-start: 12px;
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);
      position: relative;

      @include var.minmq(var.$xs) {
        padding: 18px 16px 16px;
      }

      @include var.minmq(var.$lg) {
        display: flex;
        gap: 16px;
        padding: 16px;
        margin-block-start: 14px;
      }
    }

    &--area {
      padding-block-end: 56px;
      position: relative;

      @include var.minmq(var.$xs) {
        padding-block-end: 80px;
      }

      @include var.minmq(var.$md) {
        padding-block-end: 0px;
      }

      @include var.minmq(var.$lg) {
        flex: 0 0 445px;
        padding: var.clamp-px(24px, 26px, 29px, var.$xlg) var.clamp-px(16px, 24px, 29px, var.$xlg);
      }
    }

    &--map {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      @include var.minmq(var.$md) {
        align-items: center;

        img {
          width: 390px;
        }
      }

    }

    &--store {
      flex: 0 1 100%;

      @include var.minmq(var.$lg) {
        display: block;
        position: relative;
      }
    }

    &--list {
      display: none;

      @include var.minmq(var.$lg) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
      }
    }

    &--item {
      padding: 12px 16px 12px 28px;
      background-color: var(--btn-color3);
      border-radius: var(--border-radius-small);
      border: 1px solid var(--line-color4);
      transition: border-color .4s var(--easing-cubic1);
      position: relative;

      &::before {
        content: '';
        width: 1px;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 16px;
      }

      &.is-hokkaido {
        // &::before {
        //   background-color: #52CBF7;
        // }
      }

      &.is-tohoku {
        &::before {
          background-color: #52CBF7;
        }
      }

      &.is-kanto {
        &::before {
          background-color: #A7D565;
        }
      }

      &.is-chubu {
        &::before {
          background-color: #F79E7F;
        }
      }

      &.is-kansai {
        &::before {
          background-color: #41C3BF;
        }
      }

      &.is-chugoku {
        &::before {
          background-color: #F7B547;
        }
      }

      &.is-shikoku {
        &::before {
          background-color: #B0A6CF;
        }
      }

      &.is-kyushu {
        // &::before {
        //   background-color: #52CBF7;
        // }
      }

      @include var.hover {
        border-color: #B4B0AE;
      }
    }

    &--regionbtn {
      background: none;
      font-weight: 600;
      @include var.fontsize(16);
      transition: opacity .4s var(--easing-cubic1);

      .is-label {
        pointer-events: none;
      }

      @include var.hover {
        opacity: .5;
      }

      .is-hokkaido & {
        // color: #52CBF7;
      }

      .is-tohoku & {
        color: #52CBF7;
      }

      .is-kanto & {
        color: #A7D565;
      }

      .is-chubu & {
        color: #F79E7F;
      }

      .is-kansai & {
        color: #41C3BF;
      }

      .is-chugoku & {
        color: #F7B547;
      }

      .is-shikoku & {
        color: #B0A6CF;
      }

      .is-kyushu & {
        // color: #52CBF7;
      }
    }

    &--prefbtn {
      background: none;
      font-weight: 400;
      @include var.fontsize(15);
      transition: color .4s var(--easing-cubic1);

      .is-label {
        pointer-events: none;
      }

      @include var.hover {
        color: var(--text-color1);
      }
    }

    &--pref {
      display: flex;
      flex-wrap: wrap;
      gap: 6px 1em;
    }

    &--prefitem {
      flex: 0 0 auto;
    }
  }

  &__regionbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 10px;
    gap: 6px;
    max-width: 100px;
    width: 100%;
    min-height: 40px;
    color: var(--text-color2);
    border-radius: var(--border-halfcircle);
    transition: opacity .4s var(--easing-cubic1);
    position: absolute;

    .is-label {
      font-weight: 600;
      @include var.fontsize(14);
      color: currentColor;
      pointer-events: none;
    }

    @include var.minmq(var.$xs) {
      max-width: 120px;
      min-height: 48px;

      .is-label {
        @include var.fontsize(16);
      }
    }

    &::after {
      width: 10px;
      height: 10px;
      content: '';
      background-color: currentColor;
      mask: url('../img/common/icon-arrow2.svg') center no-repeat;
      mask-size: contain;
    }

    &[aria-controls="hokkaido"] {}

    &[aria-controls="tohoku"] {
      background-color: #52CBF7;
      bottom: 48px;
      right: 0;

      @include var.minmq(var.$xs) {
        bottom: 64px;
      }

      @include var.minmq(var.$md) {
        bottom: 128px;
        right: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        bottom: auto;
        right: calc(50% - 205px);
        top: 280px;
      }
    }

    &[aria-controls="kanto"] {
      background-color: #A7D565;
      bottom: 0;
      right: 0;

      @include var.minmq(var.$md) {
        bottom: 64px;
        right: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        bottom: auto;
        right: calc(50% - 205px);
        top: 340px;
      }
    }

    &[aria-controls="chubu"] {
      background-color: #F79E7F;
      bottom: 0;
      right: 108px;

      @include var.minmq(var.$xs) {
        right: 136px;
      }

      @include var.minmq(var.$md) {
        right: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        bottom: auto;
        right: calc(50% - 205px);
        top: 400px;
      }
    }

    &[aria-controls="kansai"] {
      background-color: #41C3BF;
      top: 0;
      left: 0;

      @include var.minmq(var.$md) {
        left: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        top: 40px;
      }
    }

    &[aria-controls="chugoku"] {
      background-color: #F7B547;
      top: 48px;
      left: 0;

      @include var.minmq(var.$xs) {
        top: 64px;
      }

      @include var.minmq(var.$md) {
        left: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        top: 100px;
        left: 30px;
      }
    }

    &[aria-controls="shikoku"] {
      background-color: #B0A6CF;
      top: 96px;
      left: 0;

      @include var.minmq(var.$xs) {
        top: 128px;
      }

      @include var.minmq(var.$md) {
        left: calc(50% - 195px);
      }

      @include var.minmq(var.$lg) {
        top: 160px;
        left: 30px;
      }
    }

    &[aria-controls="kyushu"] {}

    @include var.hover {
      opacity: .5;
    }
  }

  // MARK:詳細条件で探す
  &__select {
    padding: 12px var.clamp-px(12px, 14px, 16px, var.$lg);
    background-color: var(--bg-color5);
    border-radius: var(--border-radius-small);

    @include var.minmq(var.$lg) {
      grid-area: storeselect;
    }

    &--block {
      padding: 16px 10px 10px;
      margin-block-start: 12px;
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);
      position: relative;

      @include var.minmq(var.$xs) {
        padding: 18px 16px 16px;
      }

      @include var.minmq(var.$lg) {
        padding: 16px;
        margin-block-start: 14px;
      }
    }
  }



  // MARK:モーダル　場所から探す
  &__modal {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;

    // &.is-open {
    //   display: block;
    // }

    @include var.minmq(var.$lg) {
      position: absolute;
      z-index: 10;
    }

    &[aria-hidden="true"] {
      display: none;

      .storesearch__modal--overlay {
        display: none;
        pointer-events: none;
      }
    }

    &[aria-hidden="false"] {
      display: block;
      animation: inviewfadein .4s cubic-bezier(0, 0, 0, 1) forwards;

      .storesearch__modal--overlay {
        display: block;
        pointer-events: all;
      }

      @include var.minmq(var.$lg) {
        .storesearch__modal--overlay {
          display: contents;
        }
      }

    }

    &#tohoku {
      .storesearch__modal--region {
        a {
          color: #52CBF7;
        }

        &::before {
          background-color: #52CBF7;
        }
      }
    }

    &#kanto {
      .storesearch__modal--region {
        a {
          color: #A7D565;
        }

        &::before {
          background-color: #A7D565;
        }
      }
    }

    &#chubu {
      .storesearch__modal--region {
        a {
          color: #F79E7F;
        }

        &::before {
          background-color: #F79E7F;
        }
      }
    }

    &#kansai {
      .storesearch__modal--region {
        a {
          color: #41C3BF;
        }

        &::before {
          background-color: #41C3BF;
        }
      }
    }

    &#chugoku {
      .storesearch__modal--region {
        a {
          color: #F7B547;
        }

        &::before {
          background-color: #F7B547;
        }
      }
    }

    &#shikoku {
      .storesearch__modal--region {
        a {
          color: #B0A6CF;
        }

        &::before {
          background-color: #B0A6CF;
        }
      }
    }

    &--overlay {
      // width: 100%;
      // height: 100%;
      padding: var(--header-height) var.clamp-px(20px, 26px, 26px, var.$sm) 16px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 50;
      background-color: rgba($color: #383838, $alpha: .8);

      @include var.minmq(var.$lg) {
        display: block;
        pointer-events: none;
        background: none;
        position: static;
        padding: 0;
        opacity: 1;
      }
    }

    &--container {
      display: flex;
      flex-direction: column;
      padding-block: 18px;
      padding-inline: 12px;
      height: 100%;
      pointer-events: all;
      position: relative;
      background-color: var(--bg-color5);
      border-radius: var(--border-radius-small);
    }

    &--region {
      display: flex;
      gap: 8px;
      flex: 0 0 auto;
      position: relative;
      font-weight: 600;
      margin-block-end: 20px;
      @include var.fontsize(20);

      &::before {
        display: block;
        content: '';
        width: 4px;
        height: 100%;
        border-radius: var(--border-halfcircle);
      }
    }

    &--content {
      display: grid;
      grid-template-columns: 1fr;
      align-content: flex-start;
      gap: 12px;
      flex: 0 1 100%;
      height: 100%;
      padding-inline-end: 12px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--color-gray3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-gray5);
        border-radius: var(--border-radius);
      }
    }

    &--prefname {
      font-weight: 400;
      @include var.fontsize(20);
    }

    &--preflist {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
    }

    &--prefitem {}

    &--storelink {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4px;
      padding: 12px 34px 12px 12px;
      border-radius: var(--border-radius-small);
      background-color: var(--bg-color3);
      position: relative;
      transition: opacity .4s var(--easing-cubic1);

      &::before {
        width: 24px;
        height: 24px;
        content: '';
        border: 1px solid var(--btn-color1);
        border-radius: var(--border-circle);
        background-color: transparent;
        transition: background-color .4s var(--easing-cubic1);
        position: absolute;
        top: 50%;
        right: 8px;
        translate: 0 -50%;

        @include var.minmq(var.$xs) {
          right: 16px;
        }
      }

      &::after {
        width: 24px;
        height: 24px;
        content: '';
        background-color: var(--icon-color1);
        mask: url('../img/common/icon-arrow2.svg') center no-repeat;
        mask-size: 10px auto;
        transition: background-color .4s var(--easing-cubic1);
        position: absolute;
        top: 50%;
        right: 8px;
        translate: 0 -50%;

        @include var.minmq(var.$xs) {
          right: 16px;
        }
      }

      @include var.hover {
        opacity: .5;
      }
    }

    &--storegenre {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-block-end: 0;
    }

    &--mark {
      display: grid;
      place-items: center;
      flex: 0 0 auto;
      background-color: var(--bg-color5);
      border-radius: var(--border-radius-small);
      border: 2px solid var(--line-color1);
    }

    &--storecat {
      font-weight: 500;
      color: var(--text-color3);
      @include var.fontsize(15);
    }

    &--storename {
      font-weight: 600;
      @include var.fontsize(16);
    }

    &--close {
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      background: none;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 1px;
        content: '';
        background-color: var(--btn-color2);
        border-radius: var(--border-halfcircle);
        transition: width .3s var(--easing-cubic1), rotate .3s var(--easing-cubic1), translate .3s var(--easing-cubic1);
        translate: -50% -50%;
      }

      &::before {
        rotate: -45deg;
      }

      &::after {
        rotate: 45deg;
      }
    }
  }

}