@use 'function/var';
@use 'function/root';

/*
 * [note] パンくずリスト
 * ------------------------------------------------ */

.breadcrumbs {
  margin-block-start: 16px;
  padding-inline: 26px;

  &__label {
    font-weight: 500;
    line-height: calc(28 / 12);
    color: var(--text-color3);
    transition: color .4s var(--easing-cubic1);

    @include var.fontsize(12);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    max-width: 1000px;
    margin-inline: auto;
  }

  &__item {
    display: inline-flex;
    gap: 8px;
    align-items: center;

    &:not(:last-child) {
      &::after {
        flex: 0 0 auto;
        width: 10px;
        height: 10px;
        content: '';
        background-color: var(--text-color3);
        mask: url('../img/common/icon-arrow1_light.svg') center no-repeat;
        mask-size: contain;
        rotate: 90deg;
      }
    }
  }

  &__link {
    color: var(--text-color3);
    transition: color .4s var(--easing-cubic1);

    @include var.hover {
      color: var(--text-color1);

      .breadcrumbs__label {
        color: var(--text-color1);
      }
    }
  }
}