@use 'function/var';
@use 'function/root';

/*
 * [note] 見学・体験について
 * ------------------------------------------------ */

.opendays {
  margin-block: 80px;
  padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

  @include var.minmq(var.$lg) {
    padding-inline: 26px;
    margin-block: 120px;
  }

  &__wrap {
    max-width: 578px;
    margin-inline: auto;
    background-color: #292929;
    border-radius: var(--border-radius-medium);
    position: relative;

    @include var.minmq(var.$lg) {
      max-width: 1000px;
      background-color: transparent;
    }
  }

  &__img {
    margin-block-end: 0;
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-start-start-radius: var(--border-radius-medium);
      border-start-end-radius: var(--border-radius-medium);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #383838, $alpha: .8);
      border-start-start-radius: var(--border-radius-medium);
      border-start-end-radius: var(--border-radius-medium);
      mix-blend-mode: multiply;
      pointer-events: none;
      content: '';
      z-index: 1;
    }

    @include var.minmq(var.$lg) {
      max-width: 1000px;
      margin-inline: auto;

      img {
        height: 344px;
        object-fit: cover;
        object-position: center right;
        border-radius: var(--border-radius-medium);
      }

      &::before {
        border-radius: var(--border-radius-medium);
        background: linear-gradient(90deg, #1d1d1d 35.6%, #7a7a7a 84.1%);
      }
    }
  }

  &__container {
    padding: 40px 24px;
    position: relative;
    z-index: 2;

    @include var.minmq(var.$lg) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 1000px;
      margin-inline: auto;
      width: 100%;
      height: 100%;
      padding: 48px;
      position: absolute;
      top: 0;
      left: 50%;
      translate: -50% 0;
    }
  }

  .contstitle {
    color: var(--text-color2);
    margin-block-end: 28px;
  }

  &__text {
    color: var(--text-color2);
    line-height: calc(36 / 18);
    @include var.fontsize(18);

    @include var.minmq(var.$lg) {
      max-width: 600px;
      line-height: calc(36 / 16);
      @include var.fontsize(16);
    }
  }

  &__linklist {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-block-start: 26px;

    @include var.minmq(var.$lg) {
      display: inline-grid;
      grid-template-columns: 192px 192px;
      gap: 16px;
      margin-block-start: 32px;
    }

    &--linkitem {
      display: flex;
      justify-content: center;

      @include var.minmq(var.$lg) {
        justify-content: flex-start;
      }
    }
  }
}