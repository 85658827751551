@use 'function/var';
@use 'function/root';

html {
  font-family: var(--font-default);
  font-size: 16px;
  font-weight: 400;
  font-feature-settings: 'palt' 1;
  line-height: 2;
}

body {
  position: relative;
  color: var(--color-txtbase);

  .is-spnav & {
    overflow: hidden;
  }

  .is-modal & {
    overflow: hidden;

    @include var.minmq(var.$lg) {
      overflow: auto;
    }
  }

}

/* a link
--------------------------------------------- */
a {
  color: var(--color-link);
  text-decoration: none;
}