@use 'function/var';
@use 'function/root';

.member {

  background-color: #F4F4F4;

  &_contents {
		display: flex;
		justify-content: space-between;
		max-width: 1000px;
		margin: 0 auto;
    padding-block: 120px;

    @include var.maxmq(var.$lg) {
			flex-direction: column;
			text-align: center;
			height: 360px;
			justify-content: center;
    }

    .btn.is-primary.member {
      min-width: 300px;
      width: 100%;
      padding: 20px 20px;

      @include var.maxmq(var.$lg) {
        margin: 20px 0 0 0;
        /*min-width: auto;*/
      }
    }
  }


}