@use 'function/var';
@use 'function/root';

/*
 * [note] タブパーツ
 * 動作に必要な最低限のみ設定。見た目は各ページ個別のCSSで調整
 * ------------------------------------------------ */

[data-tabgroup] {

  .tabslist {
    display: flex;

    &__item {
      flex: 0 1 100%;
    }
  }

  [data-tabtrigger] {
    transition: color .4s var(--easing-cubic1), background-color .4s var(--easing-cubic1), opacity .4s var(--easing-cubic1);

    &[aria-selected="true"] {
      color: var(--color-link);
      background-color: var(--bg-color5);
    }

    &[aria-selected="false"] {
      color: var(--text-color2);
      background-color: #C6C6C6;
    }
  }

  [data-tabpanels] {
    &>[id] {
      // transition: opacity .4s var(--easing-cubic1);

      &[aria-hidden="true"] {
        display: none;
      }

      &[aria-hidden="false"] {
        animation: inviewfadein .4s var(--easing-cubic1) forwards;
      }
    }
  }

}