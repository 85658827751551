@use 'function/var';
@use 'function/root';

/*
 * [note] リスト系パーツ
 * ------------------------------------------------ */

.dotlist {
  &__item {
    position: relative;
    padding-inline-start: 16px;

    &::before {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background-color: var(--bg-color2);
      border-radius: var(--border-halfcircle);
      translate: 0 -50%;
    }
  }
}

.circlelist {
  &__item {
    position: relative;
    padding-inline-start: 16px;

    &::before {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background-color: var(--bg-color1);
      border-radius: var(--border-circle);
      translate: 0 -50%;
    }
  }
}

.numlist {
  margin-inline-start: 18px;

  &__item {
    position: relative;
    list-style: decimal outside;

    &::marker {
      color: var(--circlelist-color);
    }
  }
}

.noticelist {
  // margin-inline-start: 18px;

  &__item {
    display: flex;
    gap: 4px;

    .is-mark {
      flex: 0 0 auto;
    }

    .is-label {
      flex: 0 1 100%;
    }
  }
}