@use 'function/var';
@use 'function/root';

.snslink {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-block: 24px;
  border-top: 1px solid var(--line-color3);

  @include var.minmq(var.$lg) {
    gap: 30px;
  }

  .gnav & {
    @include var.minmq(var.$lg) {
      display: none;
    }
  }

  .footer & {
    border-top: 1px solid var(--line-color2);
    border-bottom: 1px solid var(--line-color2);

    @include var.minmq(var.$lg) {
      justify-content: flex-end;
      border-top: none;
    }
  }

  @include var.minmq(var.$xs) {
    gap: var.clamp-px(20px, 36px, 38px, var.$sm);
  }

  &__label {
    font-weight: 400;
    line-height: calc(20 / 15);

    @include var.fontsize(14);

    @include var.minmq(var.$xs) {
      @include var.fontsize(15);
    }

    @include var.minmq(var.$lg) {
      @include var.fontsize(13);
    }
  }

  &__block {
    display: flex;
    gap: 14px;
    align-items: center;

    @include var.minmq(var.$xs) {
      gap: 18px;
    }

    @include var.minmq(var.$lg) {
      gap: 12px;

      img {
        width: auto;
        height: 24px;
      }
    }

    &--link {
      flex: 0 1 auto;
      transition: opacity .4s var(--easing-cubic1);

      @include var.hover {
        opacity: .5;
      }
    }
  }
}