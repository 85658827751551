@use 'function/var';
@use 'function/root';

.about {
  &__concept {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    &--container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      gap: 0;
      max-width: 578px;
      margin-inline: auto;
      padding-block: 80px 114px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        grid-template-columns: 38% 1fr;
        grid-template-rows: auto;
        justify-content: center;
        gap: 0;
        padding-block: 110px 184px;
      }
    }

    &--logo {
      display: grid;
      place-items: center;
      padding-block-end: 72px;

      @include var.minmq(var.$lg) {
        padding-inline-end: 72px;
        padding-block-end: 0;

        img {
          width: 240px;
          height: auto;
        }
      }

    }

    &--contents {
      padding-block-start: 40px;
      border-top: 1px solid var(--line-color1);

      @include var.minmq(var.$lg) {
        padding-inline-start: 48px;
        padding-block-start: 0;
        border-top: none;
        border-left: 1px solid var(--line-color1);
      }
      .cardlist__sublabel {
        @include var.maxmq(var.$md) {
          display: block;
          text-align: center;
        }
      }
    }

    &--hgroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      text-align: center;
      margin-block-end: 40px;

      @include var.minmq(var.$lg) {
        align-items: flex-start;
        gap: 16px;
        text-align: left;
        margin-block-end: 48px;
      }
    }

    &--sublabel {
      font-family: var(--font-en);
      font-weight: 500;
      line-height: calc(18 / 14);
      color: var(--text-color1);
      @include var.fontsize(14);
    }

    &--mainlabel {
      font-family: var(--font-en);
      font-weight: 600;
      line-height: calc(54 / 38);
      color: var(--text-color1);
      @include var.fontsize(32);

      @include var.minmq(var.$xs) {
        font-size: var.clamp-px(32px, 38px, 42px, var.$md);
      }

      @include var.minmq(var.$lg) {
        line-height: calc(60 / 42);
      }
    }

    &--text {
      max-width: 340px;
      margin-inline: auto;
      color: var(--text-color1);
      line-height: calc(40 / 18);
      font-weight: 500;
      text-align: center;
      @include var.kerning(-10);
      @include var.fontsize(15);

      @include var.minmq(var.$xs) {
        font-size: var.clamp-px(15px, 16px, 18px, var.$md);
      }

      @include var.minmq(var.$sm) {
        max-width: 100%;
      }

      @include var.minmq(var.$lg) {
        margin-inline: 0;
        line-height: calc(50 / 16);
        text-align: left;
        @include var.fontsize(16);
      }
    }
  }

  &__features {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 48px 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px;
      }
    }

  }

  &__styles {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 48px 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px;
      }
    }

    &--tblwrap {
      width: 100%;
      overflow-x: auto;
      padding-block-end: 24px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #E0E0E0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #FF9E4B;
        border-radius: var(--border-radius);
      }
    }

    &--block {
      width: 1000px;
      padding: 32px;
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);
    }

    .noticelist {
      margin-block-start: 16px;
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 64px;

      @include var.minmq(var.$lg) {
        margin-block-start: 40px;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  &__facilities {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 48px 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px;
      }
    }

    &--lead {
      font-weight: 500;
      line-height: calc(36 / 18);
      @include var.fontsize(18);
      margin-block-end: 78px;

      @include var.minmq(var.$lg) {
        line-height: calc(32 / 16);
        @include var.fontsize(16);
        margin-block-end: 56px;
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 80px;

      @include var.minmq(var.$lg) {
        flex-direction: row;
        justify-content: center;
      }
    }
  }

}