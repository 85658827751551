@use 'function/var';
@use 'function/root';

.news {
  background: #F4F4F4;

  &__container {
    padding-block: 40px;
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-block: 60px;
      padding-inline: 26px;
    }

    .news__archive {
      max-width: 578px;
      margin-inline: auto;

      @include var.minmq(var.$lg) {
        max-width: 1000px;
      }
    }

    .news__single {
      max-width: 578px;
      margin-inline: auto;

      @include var.minmq(var.$lg) {
        max-width: 1000px;
      }
    }
  }

  &__archive {
    &--item {
      border-bottom: 1px solid var(--line-color2);
    }

    &--link {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px 24px;
      padding-block: 12px;
      position: relative;
      transition: color .4s var(--easing-cubic1);
      align-items: center;

      @include var.minmq(var.$lg) {
        padding: 20px 12px;
        grid-template-columns: 190px 1fr;
      }

      @include var.hover {
        color: var(--text-color1);
      }
    }

    &--status {
      display: flex;
      gap: 16px;
      align-items: center;

      @include var.minmq(var.$lg) {
        justify-content: center;
      }
    }

    &--category {
      font-weight: 400;
      border: 1px solid var(--color-gray1);
      color: var(--color-gray1);
      border-radius: 100px;
      text-align: center;
      padding-block: 6px;
      width: 120px;

      &.important {
        color: var(--color-red1); // 重要なお知らせの場合のスタイル
        border-color: var(--color-red1);
      }

      @include var.fontsize(14);
      line-height: calc(18 / 14);

      @include var.minmq(var.$lg) {
        width: 100%;
        @include var.fontsize(13);
        translate: 0 2px;
      }

      @include var.maxmq(var.$md) {
        padding: 6px 16px;
        font-size: calc(12 / 16 * 1rem);
      }

    }

    &--date {
      font-family: var(--font-en);
      font-weight: 400;
      line-height: calc(18 / 14);
      color: var(--text-color3);
      @include var.fontsize(14);

      @include var.minmq(var.$lg) {
        @include var.fontsize(13);
      }
    }

    &--title {
      font-weight: 400;
      line-height: calc(26 / 16);
      @include var.fontsize(16);

      @include var.minmq(var.$lg) {
        @include var.fontsize(15);
        line-height: calc(18 / 15);
      }
    }

  }

  &__single {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    .img-frame {
      img {
        width: 100%;
        max-width: 100%;
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 64px;

      @include var.minmq(var.$lg) {
        margin-block-start: 40px;
        flex-direction: row;
        justify-content: center;
      }

      .btn.is-primary::after {
        right: auto;
        left: 20px;
        transform: rotate(180deg);
      }
    }
  }

  &__posttitle {
    margin-block-end: 16px;
    padding: 0 0 24px;
    padding-inline-start: 0 !important;
    border-bottom: 1px solid var(--color-gray3);


    &::after,
    &::before { display: none; }

    .news__archive--category {
      width: 120px;
    }

    .news__archive--status {
      justify-content: flex-start;
      margin-bottom: .3em;
    }
    .news__archive--date {
      color: var(--text-color3) !important;
    }
  }

  &__postbody {
    a {
      color: var(--text-color1);

      &[target="_blank"] {
        display: inline-flex;
        gap: 8px;
        align-items: center;

        &::after {
          flex: 0 0 auto;
          width: 10px;
          height: 10px;
          content: '';
          background-color: var(--icon-color2);
          mask: url('../img/common/icon-blank.svg') no-repeat;
          translate: 0 1px;
          transition: background-color .4s var(--easing-cubic1);
        }

        @include var.hover {

          // color: var(--btn-color1);
          &::after {
            background-color: var(--btn-color1);
          }
        }
      }
    }
  }

  &__postimage {
    .img-frame {
      margin-block-start: 24px;
    }
  }

  &__tab {
    display: flex;
    justify-content: left;
    margin: 0 auto;
    max-width: 578px;
    border-bottom: 1px solid var(--color-orange1);

    @include var.minmq(var.$lg) {
      max-width: 1000px;
    }
    @include var.maxmq(var.$lg) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    li {
      /*text-align: center;*/
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 56px;
      border-start-start-radius: var(--border-radius-small);
      border-start-end-radius: var(--border-radius-small);
      font-weight: 600;
      line-height: calc(22 / 16);
      @include var.fontsize(16);
      transition: color .4s var(--easing-cubic1), background-color .4s var(--easing-cubic1), opacity .4s var(--easing-cubic1);
      @include var.minmq(var.$lg) {
        width: 190px;
      }
  

      a {
        width: 100%;
        padding: 16px 8px;
        border-radius: 8px 8px 0 0;
        color: var(--color-gray5);
        background: transparent;
        transition: all .3s linear;
        font-size: calc(15 / 16 * 1rem);
        display: block;
        border: 1px solid #fc7300;
        border-bottom: none;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-inline: 8px;
        min-height: 56px;
        border-start-start-radius: var(--border-radius-small);
        border-start-end-radius: var(--border-radius-small);
        font-weight: 600;
        line-height: calc(22 / 16);
        @include var.fontsize(16);
        transition: color .4s var(--easing-cubic1), background-color .4s var(--easing-cubic1), opacity .4s var(--easing-cubic1);

        @include var.maxmq(var.$lg) {
          padding: 0;
        }
      }
    }

    li:hover{
      a {color: var(--color-orange1);}
    }

    li.active {
      /*background: #fff;
      border-radius: 8px 8px 0 0;*/
      a {
        /* background: #fff; */
        /* color: var(--color-orange1) !important; */
        background-color: var(--bg-color1);
        color: var(--text-color2);
        cursor: none;
      }
    }
  }

  .pager {
    padding: 0 24px 60px;
  }


}