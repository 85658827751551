@use 'function/var';
@use 'function/root';

/*
 * [note] テキストリンク、ボタン用パーツ
 * ------------------------------------------------ */

// MARK:ボタン
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: var(--border-halfcircle);
  transition: background-color .4s var(--easing-cubic1), color .4s var(--easing-cubic1);
  position: relative;

  &.is-primary {
    max-width: 288px;
    padding: 19px 20px;
    color: var(--text-color1);
    border: 1px solid var(--btn-color1);
    background-color: var(--btn-color3);

    .is-label {
      font-weight: 500;
      line-height: calc(24 / 18);

      @include var.fontsize(18);
    }

    @include var.minmq(var.$lg) {
      max-width: 192px;
      padding: 17px 20px;

      .is-label {
        line-height: calc(20 / 15);

        @include var.fontsize(15);
      }
    }

    &::after {
      width: 10px;
      height: 10px;
      content: '';
      background-color: currentColor;
      mask: url('../img/common/icon-arrow2.svg') center no-repeat;
      mask-size: contain;
      position: absolute;
      top: 50%;
      right: 20px;
      translate: 0 -50%;
    }

    @include var.hover {
      color: var(--text-color2);
      background-color: var(--btn-color1);
    }

    &.is-darkcolor {
      color: var(--text-color2);
      border: 1px solid var(--btn-color3);
      background-color: transparent;

      @include var.hover {
        color: var(--text-txtbase);
        background-color: var(--btn-color3);
      }
    }
  }

  &[target="_blank"] {
    &::after {
      width: 10px;
      height: 10px;
      content: '';
      background-color: currentColor;
      mask: url('../img/common/icon-blank.svg') no-repeat;
      mask-size: contain;
      position: absolute;
      top: 50%;
      right: 20px;
      translate: 0 -50%;
    }
  }
}


// MARK:テキストリンク
.linktext {
  color: var(--btn-color1);

  .is-label {
    @include var.fontsize(15);
    font-weight: 500;
  }

  &.is-arrow {
    display: inline-flex;
    gap: 6px;
    align-items: center;
    transition: opacity .4s var(--easing-cubic1), color .4s var(--easing-cubic1);
    position: relative;
    padding-inline-end: 32px;

    &::before {
      width: 24px;
      height: 24px;
      content: '';
      border: 1px solid var(--btn-color1);
      border-radius: var(--border-circle);
      background-color: transparent;
      transition: background-color .4s var(--easing-cubic1);
      position: absolute;
      top: 50%;
      right: 0;
      translate: 0 -50%;
    }

    &::after {
      width: 24px;
      height: 24px;
      content: '';
      background-color: var(--icon-color1);
      mask: url('../img/common/icon-arrow2.svg') center no-repeat;
      mask-size: 10px auto;
      transition: background-color .4s var(--easing-cubic1);
      position: absolute;
      top: 50%;
      right: 0;
      translate: 0 -50%;
    }

    @include var.hover {
      opacity: .5;
    }

  }

  &[target="_blank"] {
    display: inline-flex;
    gap: 2px;
    align-items: center;
    margin-inline-end: 4px;
    transition: color .4s var(--easing-cubic1);

    &::after {
      width: 10px;
      height: 10px;
      content: '';
      background-color: var(--icon-color2);
      mask: url('../img/common/icon-blank.svg') no-repeat;
      translate: 0 1px;
      transition: background-color .4s var(--easing-cubic1);
    }

    @include var.hover {
      color: var(--btn-color);
    }
  }

}