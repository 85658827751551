@use 'function/var';
@use 'function/root';

/*
 * [note] タイトル、テキスト系パーツ
 * ------------------------------------------------ */

.contstitle {
  position: relative;

  &.is-primary {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: var(--color-txtbase);
    padding-inline-start: 14px;

    @include var.minmq(var.$lg) {
      padding-inline-start: 22px;
    }

    .is-sublabel {
      font-family: var(--font-en);
      font-weight: 500;
      line-height: calc(18 / 14);
      color: var(--text-color1);
      @include var.fontsize(14);
    }

    .is-mainlabel {
      line-height: calc(36 / 24);
      // color: var(--text-color1);
      font-size: var.clamp-px(24px, 26px, 28px, var.$lg);
      font-weight: 600;
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      height: 100%;
      content: '';
      border-radius: var(--border-halfcircle);
      background: linear-gradient(0deg, var(--ttlbar-color2) 50%, var(--ttlbar-color1) 50%);
    }
  }

  &.is-secondary {
    padding-block-end: 24px;
    margin-block-end: 32px;
    border-bottom: 1px solid var(--line-color3);

    .is-label {
      line-height: calc(33 / 24);
      // color: var(--text-color1);
      @include var.fontsize(24);
      font-weight: 600;
    }
  }

  &.is-tertiary {
    .is-label {
      line-height: calc(24 / 18);
      // color: var(--text-color1);
      font-weight: 600;
      @include var.fontsize(18);
    }
  }

}


.text {
  font-weight: 400;
  line-height: calc(28 / 15);

  @include var.fontsize(15);
  @include var.kerning(-10);

  &+& {
    margin-block-start: 1.8em;
  }

  &.is-xxlsize {
    @include var.fontsize(24);
  }

  &.is-xlsize {
    line-height: calc(32 / 18);
    @include var.fontsize(18);
  }

  &.is-lsize {
    line-height: calc(32 / 16);
    @include var.fontsize(16);
  }

  &.is-ssize {
    line-height: calc(24 / 14);

    @include var.fontsize(14);
  }

  &.is-xsize {
    line-height: calc(24 / 12);

    @include var.fontsize(12);
  }
}