@use 'function/var';
@use 'function/root';

.introduction {

  &__partner {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 64px 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px;
      }
    }

    &--title {
      font-family: var(--font-en);
      font-weight: 600;
      line-height: calc(49 / 40);
      color: var(--text-color1);
      @include var.fontsize(40);
      margin-block-end: 40px;
      margin-inline: -10px;
      text-align: center;

      .is-label {
        display: block;
      }

      @include var.minmq(var.$xs) {
        font-size: var.clamp-px(40px, 44px, 48px, var.$md);
      }

      @include var.minmq(var.$lg) {
        line-height: calc(60 / 42);
        @include var.fontsize(48);
        margin-block-end: 48px;

        .is-label {
          display: inline;
        }

      }
    }

    &--read {
      font-weight: 500;
      line-height: calc(44 / 17);
      @include var.fontsize(17);
      @include var.kerning(-10);
      text-align: center;
      margin-inline: -20px;

      @include var.minmq(var.$lg) {
        line-height: calc(40 / 16);
        @include var.fontsize(16);
      }
    }

    &--list {
      display: grid;
      grid-template-columns: auto;
      margin-block: 64px 40px;
      max-width: 320px;
      margin-inline: auto;
      place-items: center;

      @include var.minmq(var.$lg) {
        justify-content: center;
        grid-template-columns: repeat(3, auto);
        margin-block: 64px 48px;
      }

    }

    &--item {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      justify-content: center;
      gap: 24px;
      width: 280px;
      aspect-ratio: 1 / 1;
      padding: 32px 0;
      position: relative;

      @include var.minmq(var.$xs) {
        width: 320px;
        padding: 56px 0;
      }

      &:nth-child(even) {
        margin-block: -16px;
        z-index: 1;

        @include var.minmq(var.$lg) {
          margin-block: 0;
          margin-inline: -16px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        inset: 50%;
        width: 97%;
        height: 97%;
        aspect-ratio: 1 / 1;
        translate: -50% -50%;
        z-index: 2;
        border-radius: var(--border-circle);
        background-color: #ff8227;
        mix-blend-mode: multiply;
      }
    }

    &--support {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding-block: 16px;
      max-width: 160px;
      width: 100%;
      margin-inline: auto;
      border-top: 1px solid #FF9E4B;
      border-bottom: 1px solid #FF9E4B;
      text-align: center;
      line-height: 1;
      position: relative;
      z-index: 2;

      .is-label {
        color: var(--text-color2);
        font-family: var(--font-en);
        @include var.fontsize(14);
        font-weight: 500;
      }

      .is-number {
        color: var(--text-color2);
        font-family: var(--font-en);
        @include var.fontsize(36);
        font-weight: 700;
      }
    }

    &--listtext {
      max-width: 260px;
      width: 100%;
      margin-inline: auto;
      color: var(--text-color2);
      line-height: calc(36 / 18);
      @include var.fontsize(16);
      text-align: center;
      position: relative;
      z-index: 2;

      @include var.minmq(var.$sm) {
        line-height: calc(24 / 15);
        font-size: calc(15 / 15 * 1rem);
      }

      @include var.minmq(var.$md) {
        line-height: calc(24 / 15);
        font-size: calc(15 / 15 * 1rem);
      }

    }

    &--text {
      font-weight: 400;
      line-height: calc(36 / 18);
      @include var.fontsize(18);
      @include var.kerning(-10);
      text-align: center;

      @include var.minmq(var.$lg) {
        line-height: calc(40 / 16);
        @include var.fontsize(16);
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 64px;

      @include var.minmq(var.$lg) {
        margin-block-start: 40px;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  &__reasons {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 48px 0;
      // border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px 0;
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 64px;

      @include var.minmq(var.$lg) {
        margin-block-start: 40px;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  &__admission {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 0 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 0 120px;
      }
    }

    &--contents {
      @include var.minmq(var.$lg) {
        display: flex;
        gap: 110px;
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 36px;

      @include var.minmq(var.$lg) {
        flex: 0 0 192px;
        margin-block-start: 0;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  &__faq {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    .contstitle {
      margin-block-end: 36px;

      @include var.minmq(var.$lg) {
        margin-block-end: 44px;
      }

    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      padding-block: 48px 120px;
      border-bottom: 1px solid var(--line-color3);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding-block: 120px;
      }
    }

    &--block {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
      padding: 8px;
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);

      &+& {
        margin-block-start: 24px;
      }
    }

    &--question {
      display: flex;
      gap: 8px;
      padding: 8px;

      .is-label {
        flex: 0 1 100%;
        font-weight: 600;
        line-height: calc(26 / 18);
        color: var(--text-color1);
        @include var.fontsize(18);
      }

      &::before {
        flex: 0 0 auto;
        content: 'Q.';
        font-family: var(--font-en);
        font-weight: 600;
        line-height: 1;
        color: var(--text-color1);
        @include var.fontsize(24);
      }
    }

    &--answer {
      display: flex;
      gap: 8px;
      padding: 16px 8px;
      background-color: var(--bg-color5);
      border-radius: var(--border-radius);

      &::before {
        flex: 0 0 auto;
        content: 'A.';
        font-family: var(--font-en);
        font-weight: 600;
        line-height: 1;
        color: #C6C6C6;
        translate: 2px 2px;
        @include var.fontsize(24);
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-block-start: 40px;

      @include var.minmq(var.$lg) {
        margin-block-start: 48px;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

}