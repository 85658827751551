@use 'function/var';
@use 'function/root';

.admission {
  &__step {
    padding-inline: var.clamp-px(20px, 26px, 26px, var.$sm);

    @include var.minmq(var.$lg) {
      padding-inline: 26px;
    }

    &--container {
      max-width: 578px;
      margin-inline: auto;
      margin-block-start: 36px;
      padding: 12px;
      background-color: var(--bg-color3);
      border-radius: var(--border-radius-small);

      @include var.minmq(var.$lg) {
        max-width: 1000px;
        padding: 16px;
        margin-block-start: 48px;
        border-radius: var(--border-radius-medium);
      }
    }

    &--tabslist {
      @include var.minmq(var.$lg) {
        gap: 16px;
      }
    }

    &--tablink {
      display: grid;
      place-items: center;
      min-height: 64px;
      height: 120px;
      position: relative;
      text-align: center;
      font-weight: 500;
      line-height: calc(26 / 14);
      border-start-start-radius: var(--border-radius-medium);
      border-start-end-radius: var(--border-radius-medium);
      @include var.fontsize(14);
      @include var.kerning(-40);

      @include var.minmq(var.$xs) {
        line-height: calc(28 / 15);
        @include var.fontsize(15);
      }

      @include var.minmq(var.$lg) {
        line-height: calc(30 / 20);
        @include var.fontsize(20);
        @include var.kerning(-10);
      }

      &::before {
        display: block;
        content: '';
        height: 4px;
        border-radius: var(--border-halfcircle);
        background-color: var(--line-color1);
        transition: opacity .4s var(--easing-cubic1);
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;

        @include var.minmq(var.$lg) {
          top: 16px;
          left: 24px;
          right: 24px;
        }
      }

      &[aria-selected="true"] {
        padding-block: 28px 12px;

        &::before {
          opacity: 1;
        }
      }

      &[aria-selected="false"] {
        padding-block: 12px;

        &::before {
          opacity: 0;
        }
      }
    }
  }

  &__contents {
    padding: 24px 16px 40px;
    border-end-start-radius: var(--border-radius-small);
    border-end-end-radius: var(--border-radius-small);
    background-color: var(--bg-color5);

    @include var.minmq(var.$lg) {
      padding: 52px 24px 48px;
    }

    &--list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 64px;

      @include var.minmq(var.$lg) {
        gap: 48px;
      }

    }

    &--item {
      background-color: var(--bg-color3);
      border: 1px solid var(--line-color1);
      border-radius: var(--border-radius-medium);
      position: relative;

      @include var.minmq(var.$lg) {
        display: grid;
        grid-template-columns: 96px 1fr;
        min-height: 144px;
      }

      &:not(:first-child) {
        &::before {
          width: 25px;
          height: 25px;
          content: '';
          background-color: var(--icon-color4);
          mask: url('../img/common/icon-arrow1_bold.svg') center no-repeat;
          mask-size: contain;
          position: absolute;
          top: 0;
          left: 50%;
          rotate: 180deg;
          translate: -50% calc(-100% - 24px);

          @include var.minmq(var.$lg) {
            translate: -50% calc(-100% - 12px);
          }

        }
      }
    }

    &--number {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 12px;
      background-color: var(--bg-color1);
      border-start-start-radius: var(--border-radius-small);
      border-start-end-radius: var(--border-radius-small);

      @include var.minmq(var.$lg) {
        flex-direction: column;
        gap: 0;
        // border-start-start-radius: var(--border-radius-small);
        border-start-end-radius: 0;
        border-end-start-radius: var(--border-radius-small);
      }

      .is-label {
        color: var(--text-color2);
        font-weight: 600;
        line-height: calc(22 / 18);
        @include var.fontsize(18);

        @include var.minmq(var.$lg) {
          @include var.fontsize(16);
        }

      }

      .is-step {
        color: var(--text-color2);
        font-weight: 600;
        line-height: calc(22 / 18);
        @include var.fontsize(18);

        @include var.minmq(var.$lg) {
          @include var.fontsize(36);
          line-height: 1;
        }

      }
    }

    &--inner {
      padding: 16px;

      @include var.minmq(var.$lg) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
      }
    }

    &--text {
      color: var(--text-color1);
      font-weight: 600;
      line-height: calc(32 / 20);
      @include var.fontsize(20);

      @include var.minmq(var.$lg) {
        line-height: calc(24 / 18);
        @include var.fontsize(18);
      }

    }

    &--btn {
      display: flex;
      justify-content: center;
      margin-block-start: 16px;

      @include var.minmq(var.$lg) {
        flex: 0 0 192px;
        margin-block-start: 0;
      }
    }

    .noticelist {
      margin-block-start: 16px;

      &__item {
        font-weight: 400;
        @include var.fontsize(15);

        @include var.minmq(var.$lg) {
          @include var.fontsize(13);
        }

      }
    }

  }

  &__block {

    &--section {
      padding-block: 40px 36px;

      @include var.minmq(var.$lg) {
        padding-block: 64px 48px;
      }

      &+& {
        padding-block: 36px;
        border-top: 1px solid var(--line-color3);

        @include var.minmq(var.$lg) {
          padding-block: 48px;
        }
      }

      &:last-child {
        padding-block-end: 0 !important;
      }

      &.is-need {

        // .numlist {
        //   &__item {
        //     line-height: calc(32 / 20);
        //     @include var.fontsize(20);
        //   }
        // }

        .noticelist {
          margin-block-start: 8px;
        }
      }

      &.is-payment {}

    }

    &--subtitle {
      margin-block-end: 12px;

      @include var.minmq(var.$lg) {
        margin-block-end: 21px;
      }
    }

    &--btn {
      display: flex;
      justify-content: center;
      margin-block-start: 36px;

      @include var.minmq(var.$lg) {
        margin-block-start: 46px 0;
      }
    }

  }




  .opendays {
    margin-block-start: 80px;
  }
}