@use 'function/var';
@use 'function/root';

/*
 * [note] ページトップボタン
 * ------------------------------------------------ */

// MARK:ページトップ
.fixedbtn {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 12;
  display: block;
  width: 48px;
  height: 48px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .4s var(--easing-cubic1);

  @include var.minmq(var.$lg) {
    right: 32px;
    bottom: 32px;
  }

  &.is-show {
    pointer-events: all;
    opacity: 1;
  }

  &.is-hide {
    pointer-events: none;
    opacity: 0;
  }


  &__pagetop {
    position: relative;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--btn-color2);
    border: 1px solid var(--btn-color2);
    border-radius: var(--border-circle);
    transition: background-color .4s var(--easing-cubic1);

    // .is-label {
    //   display: none;
    // }

    &::before {
      width: 14px;
      height: 14px;
      content: '';
      background-color: var(--icon-color3);
      mask: url('../img/common/icon-arrow1_bold.svg') center no-repeat;
      mask-size: contain;
    }

    @include var.hover {
      background-color: var(--color-white);

      &::before {
        background-color: var(--btn-color2);
      }
    }
  }
}