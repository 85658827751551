@use 'function/var';
@use 'function/root';

/*
 * [note] ページャー用
 * ------------------------------------------------ */

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-block: 40px 24px;
}

.page-numbers {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  line-height: calc(18 / 13);
  @include var.fontsize(13);
  font-weight: 500;
  color: var(--text-color3);
  transition: color .4s var(--easing-cubic1);

  @include var.hover {
    color: var(--text-color1);
  }

  &.current {
    color: var(--text-color1);
  }

  &.prev,
  &.next {
    font-size: 0;
    position: relative;

    &::before {
      width: 10px;
      height: 10px;
      content: '';
      background-color: currentColor;
      mask: url('../img/common/icon-arrow2.svg') center no-repeat;
      mask-size: 10px auto;
      transition: background-color .4s var(--easing-cubic1);
      position: absolute;
      top: 50%;
      right: 0;
      translate: 0 -50%;
    }
  }

  &.prev {
    &::before {
      scale: -1 1;
    }
  }

  &.next {}
}